import styled, { css } from 'styled-components'
import SearchBox from './search-box'

const open = css`
  width: 10em;
  background: ${ ({ theme }) => theme.background };
  cursor: text;
  border-radius: 5px;
  border: rgba(0, 0, 0, 0.2) solid 1px;
  padding: 8px 10px;
  margin-left: -1.6em;
  padding-left: 2em;

  ${ props => props.theme.media.phone`
    display: block;
  ` }
`

const openWrapper = css`
  ${ props => props.theme.media.phone`
    padding: 20px;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
  ` }
`

const openClose = css`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.foreground};
  pointer-events: none;
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;

  .SearchWrapper {
    ${({ hasFocus }) => (hasFocus ? openWrapper : null)}
  }

  .SearchInput {
    outline: none;
    border: ${({ hasFocus }) => (hasFocus ? 'auto' : 'none')};
    transition: 100ms;
    border-radius: 2px;
    color: ${({ theme }) => theme.foreground};
    font-size: 1rem;
    font-family: ${ props => props.theme.bodyFont };

    ::placeholder {
      color: ${({ theme }) => theme.faded};
      font-size: 1rem;
      font-family: ${ props => props.theme.bodyFont };
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    width: 20px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
  }

  .CloseIcon {
    display: none;
    z-index: 10000;

    ${ props => props.theme.media.phone`
      ${({ hasFocus }) => (hasFocus ? openClose : null)}
    `}
  }
`
