import React from 'react'
import Transition from './src/components/Transition'
import { ThemeProvider, css } from 'styled-components'
import { GlobalWrapper } from './src/components/mainContainer'

export const navHeight = 80
const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${ sizes[label] }px) {
      ${ css(...args) }
    }
  `

  return acc
}, {})

const theme = {
  foreground: 'rgb(4, 13, 20)',
  background: 'white',
  faded: '#888',

  primaryColor: '#f1ebe6',
  secondaryColor: '#797470',
  black: 'rgb(4, 13, 20)',
  footerHeight: 50,
  footerMargin: 20,
  footerBg: '#f1ebe6',
  subscribePrimary: '#9e8a74',
  navHeight: `${ navHeight }px`,
  navHeightRaw: navHeight,
  navBorderWidth: '2px',
  navColor: '#e3e6ea',
  footerColor: '#e3e6ea',
  headerFont: '"Cormorant Garamond", Tahoma, Arial, Helvetica, sans-serif',
  cursiveFont: 'TheSomething, cursive',
  bodyFont: "'Glacial Indifference', sans-serif",
  bodyWeight: 300,
  bodySize: '1rem',
  media
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <GlobalWrapper path={props.location.pathname}>
      <Transition {...props}>
        {element}
      </Transition>
    </GlobalWrapper>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  )
}
