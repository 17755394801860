import styled, { css } from 'styled-components'
import SearchResult from './search-result'

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 1em;
  border-radius: 2px;
  background: ${({ theme }) => theme.background};

  ${ props => props.theme.media.phone`
    top: 50px;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
  ` }
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }

    li.ais-Hits-item {
      border: rgba(0, 0, 0, 0.2) solid 0;
      border-bottom-width: 1px;
      padding: 0;
      margin: 0;

      a {
        color: ${({ theme }) => theme.foreground};
        margin: 0;
        display: block;
        padding: 10px 0;
        text-decoration: none;
        transition: all 0.25s;

        &:hover, &:focus {
          background-color: ${ props => props.theme.primaryColor };
          font-weight: 600;
          transition: all 0.25s;
        }

        h4 {
          font-size: 1rem;
          padding: 0.1em 0;
          margin: 0;
          font-family: ${ props => props.theme.headerFont };
        }

        h5 {
          font-size: 0.9rem;
          padding: 0.1em 0;
          margin: 0;
          font-family: ${ props => props.theme.headerFont };
        }
      }

      .ais-Snippet {
        font-size: 0.8rem;
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }
  }
`
