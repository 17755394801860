import React, { Component } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { BlockWrapper } from './containers'

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
`

const Cursive = styled.div`
  font-family: ${ props => props.theme.cursiveFont };
  margin-bottom: -60px;
  font-size: 7rem;
  text-align: left;
  color: ${ props => props.theme.black };
  text-align: center;

  color: ${ props => props.theme.subscribePrimary };

  ${ props => props.theme.media.phone`
    text-align: center;
    margin-bottom: -40px;
  ` }
`

const FormWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  background: url(/subscribe-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 30px;
  font-family: ${ props => props.theme.bodyFont };
`

const Container = styled(props => <BlockWrapper {...props} />)`
  max-width: 600px;
  margin: 0 auto;
`

const FormGroup = styled.div`
  width: 300px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const ErrorMsg = styled.div`
  color: red;
  font-weight: 500;
`

const Header = styled.h3`
  font-size: 3rem;
  line-height: 3.2rem;
  letter-spacing: 3px;
  padding: 0;
  margin: 0;
  color: ${ props => props.theme.black };
  font-family: ${ props => props.theme.headerFont };
  text-transform: uppercase;
  padding-bottom: 20px;

  color: ${ props => props.theme.subscribePrimary };
`

const Bar = styled.div`
  width: 200px;
  height: 1px;
  margin: 0 auto;
  background-color: ${ props => props.theme.subscribePrimary };
`

const Subtitle = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 20px 0;
  margin: 0;
  margin-bottom: 10px;
  font-family: ${ props => props.theme.bodyFont };
  color: ${ props => props.theme.subscribePrimary };
`

const Input = styled.input`
  background-color: white;
  box-sizing: border-box;
  border: ${ props => props.theme.footerBg } solid 1px;
  border-radius: 2px;
  border-width: 1px;
  box-shadow: none;
  color: ${ props => props.theme.black };
  font-family: ${ props => props.theme.bodyFont };
  font-size: 1rem;
  margin: 0;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  display: block;

  &:focus {
    border-color: ${ props => props.theme.black };
    outline: none !important;
  }

  &::placeholder {
    font-family: ${ props => props.theme.bodyFont };
    color: ${ props => props.theme.subscribePrimary };
  }
`

const Button = styled.button`
  background-color: ${ props => props.theme.subscribePrimary };
  box-sizing: border-box;
  border-color: ${ props => props.theme.footerBg };
  border-radius: 2px;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: ${ props => props.theme.bodyFont };
  font-size: 1rem;
  margin: 0;
  margin-bottom: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  width: 100%;
  transition: 0.25s all;

  &:focus {
    border-color: ${ props => props.theme.black };
    outline: none !important;
  }

  &:hover {
    opacity: 0.8;
    transition: 0.25s all;
  }
`

export class MailChimpSubscribe extends Component {
  state = {
    email: null,
    first_name: null,
    state: null,
    error: null
  }

  _handleSubmit = async e => {
    e.preventDefault()
    try {
      this.setState({
        state: 'loading'
      })
      const result = await addToMailchimp(this.state.email, {
        'FNAME': this.state.first_name
      })
      if (result.result === 'success') {
        this.setState({
          state: 'subscribed'
        })
      } else {
        throw Error(result.result)
      }
    } catch (e) {
      this.setState({
        state: 'error',
        error: `Oh no! We can't subscribe you right now!`
      })
    }
    return false
  }

  render () {
    if (this.state.state === 'subscribed') {
      return (
        <FormWrapper>
          <Container>
            <Header>You've Subscribed!</Header> <Subtitle>
              Thank you for subscribing!
            </Subtitle>
          </Container>
        </FormWrapper>
      )
    } else {
      return (
        <FormWrapper>
          <Container>
            <form onSubmit={this._handleSubmit}>
              <HeaderBlock>
                <Cursive>Oh hello</Cursive>
                <Header>Stay in the Know</Header>
              </HeaderBlock>
              <Bar />
              <Subtitle>
                A monthly newsletter sharing content and products to help you
                through your motherhood journey.
              </Subtitle>
              <FormGroup>
                <Input
                  type="text"
                  name="first_name"
                  required
                  placeholder="First Name..."
                  onChange={e => this.setState({ first_name: e.target.value })} />
                <Input
                  type="email"
                  name="email"
                  required
                  placeholder="Email..."
                  onChange={e => this.setState({ email: e.target.value })} />
                <Button type="submit" disabled={this.state.state === 'loading'}>
                  Subscribe
                </Button>
                { this.state.state === 'error' &&
                  <ErrorMsg>{ this.state.error }</ErrorMsg>
                }
              </FormGroup>
            </form>
          </Container>
        </FormWrapper>
      )
    }
  }
}
