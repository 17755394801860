import styled from 'styled-components'

export default styled.div`
  position: relative;
  margin: 0.6em 0;

  ${ props => props.theme.media.phone`
    position: inherit;
  ` }
`
