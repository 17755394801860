import React from 'react'
import styled from 'styled-components'
import Modal from 'react-overlays/Modal'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'

import { MailChimpSubscribe } from '../mailchimp'

const ImageWrapper = styled.div`
  max-width: 300px;
  padding: 0;
  margin: 0 auto;
`

const OfficeImage = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "office.jpg" }) {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }`
    }
    render={ data => (
      <ImageWrapper>
        <Img
          loading="eager"
          fadeIn={false}
          fixed={data.logo.childImageSharp.fixed} />
      </ImageWrapper>
    )} />
)

const Backdrop = styled.div`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`

const SubModal = styled(props => <Modal {...props} />)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1040;
  border: none;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 0;
  outline: 0;
`

const ModalGrid = styled.div`
  display: flex;
  flex-direction: row;
`

const CloseButton = styled.button`
  position: absolute;
  point: cursor;
  right: 0;
  top: 0;
  padding: 10px;
  border: none;
  font-size: 3rem;
  margin: 0;
  height: 50px;
  width: 50px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`

const SubscribeModal = ({ show, setShow }) => {
  const renderBackdrop = props => <Backdrop onClick={ () => setShow(false) } />

  return (
    <SubModal
      show={show}
      onHide={() => setShow(false)}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label">
      <div>
        <CloseButton onClick={() => setShow(false) }>&times;</CloseButton>
        <ModalGrid>
          <MailChimpSubscribe />
        </ModalGrid>
      </div>
    </SubModal>
  )
}

export {
  SubscribeModal
}
