import React, { PureComponent } from 'react'
import { motion } from 'framer-motion'

const variants = {
  enter: {
    opacity: 1,
    transition: { duration: 250 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 250 }
  }
}

const RoutesContainer = props =>
  <motion.div variants={variants} {...props} />

class Transition extends PureComponent {
  render () {
    const { children, location } = this.props
    return (
      <RoutesContainer key={location.pathname}>
        {children}
      </RoutesContainer>
    )
  }
}

export default Transition
