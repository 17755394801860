import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { Search as SearchIcon } from '@styled-icons/fa-solid'

function close (e) {
}

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <div className="SearchWrapper">
        <input
          className="SearchInput"
          type="text"
          id="SearchInput"
          placeholder="Search"
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
      </div>
      <SearchIcon className="SearchIcon" />
    </form>
  )
)
