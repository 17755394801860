import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  max-width: 998px;
  flex: 1 1 auto;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
`

const PageWrapper = styled(props => <ContentWrapper {...props} />)`
  padding-top: 0;

  ${ props => props.theme.media.desktop`
    padding-top: 0;
  ` }
`

const BlockWrapper = styled(props => <ContentWrapper {...props} />)`
  padding-top: 50px;
  padding-bottom: 25px;
`

const ContentBlock = styled.div`
  min-height: 'calc(60vh - ${
  props => props.theme.footerHeight + props.theme.footerMargin
}px)';
`

export {
  ContentWrapper,
  BlockWrapper,
  PageWrapper,
  ContentBlock
}
