import React from 'react'

export const AMAZON_AFFILIATE_ID = 'inspiredfas04-20'
export const INSTAGRAM_HANDLE = 'hortonlane_'
export const INSTAGRAM_LINK = 'https://www.instagram.com/hortonlane_'
export const PINTEREST_LINK = 'https://www.pinterest.com/HortonLane_'
// export const FACEBOOK_LINK = 'https://www.facebook.com/inspiredfashionfinds/'
export const TIKTOK_LINK = 'https://www.tiktok.com/@hortonlane'
export const EMAIL_LINK = 'mailto:chrissy@hortonlane.com'
export const SmartLink = props => {
  const clazz = typeof window !== 'undefined' &&
      window.location.pathname === props.to
    ? 'active'
    : null
  return (
    <props.component className={clazz} {...props}>
      {props.children}
    </props.component>
  )
}

// TODO: make this function more robust
export const formatAffiliateLink = link => {
  if (link.match(/amazon.com/)) {
    if (link.indexOf('?') === -1) {
      return link + '?tag=' + AMAZON_AFFILIATE_ID
    } else {
      return link + '&tag=' + AMAZON_AFFILIATE_ID
    }
  } else {
    return link
  }
}

export const formatBuyText = link => {
  if (link.match(/amazon.com/)) {
    return 'Get It on Amazon'
  } else if (link.match(/wayfair.com/)) {
    return 'Get It on Wayfair'
  } else {
    return 'Get this now!'
  }
}

export const updateReviewDom = () => {
  const review = document.getElementById('review')
  if (review) {
    const links = review.querySelectorAll('a')
    links.forEach(l => {
      l.target = '_blank'
      l.rel = 'noopener'
      l.href = formatAffiliateLink(l.href)
    })
  }
}

export const join = (collections, products) => {
  return [].concat(
    collections.map(c => ({
      type: 'collection',
      slug: c.node.slug,
      url: `/collection/${ c.node.slug }/`,
      obj: c,
      date: new Date(c.node.publishedDate).getTime()
    }))
  ).concat(
    products.map(p => ({
      type: 'product',
      slug: p.node.slug,
      url: `/product/${ p.node.slug }/`,
      obj: p,
      date: new Date(p.node.publishedDate).getTime()
    }))
  ).sort((a, b) => b.date - a.date)
}
