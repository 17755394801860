// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-instagram-stories-js": () => import("./../../../src/pages/instagram/stories.js" /* webpackChunkName: "component---src-pages-instagram-stories-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sms-test-js": () => import("./../../../src/pages/sms-test.js" /* webpackChunkName: "component---src-pages-sms-test-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-collection-list-js": () => import("./../../../src/templates/collection-list.js" /* webpackChunkName: "component---src-templates-collection-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

