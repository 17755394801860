import React from 'react'
import {
  INSTAGRAM_HANDLE,
  INSTAGRAM_LINK
} from '../utils'
import { Instagram } from 'styled-icons/fa-brands'
import InstagraPic from '../images/instapic.jpg'
import styled from 'styled-components'

const InstaLinkStyle = styled.a`
  align-items: center;
  color: ${ props => props.theme.black };
  display: flex;
  font-size: 2rem;
  text-decoration: none;
  margin: 0 15px;
  transition: all 0.25s;
  opacity: 0.95;

  &:hover {
    transform: scale(1.02);
    transition: all 0.25s;
    opacity: 1;
  }
`

const InstagramIcon = styled(props => <Instagram {...props} />)`
  color: ${ props => props.theme.black };
  width: 40px;
  padding: 0 15px;
`

export const InstagramImage = styled.img`
  width: ${ props => props.dim }px;
  height: ${ props => props.dim }px;
  padding: 0;
  clip-path: circle(${ props => props.dim / 2 }px at center);
`

const Handle = styled.div`
  padding: 0 15px;
`

const LayoutBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 auto;
`

const Layout = styled(props => <LayoutBase {...props} />)`
  justify-content: center;

  ${ props => props.theme.media.tablet`
    flex-flow: wrap;
    flex-direction: column;
  ` }
`

const LayoutLeft = styled(props => <LayoutBase {...props} />)`
  justify-content: flex-end;
  width: 50%;

  ${ props => props.theme.media.tablet`
    justify-content: center;
    width: 100%;
  ` }
`

const LayoutRight = styled(props => <LayoutBase {...props} />)`
  justify-content: flex-start;

  ${ props => props.theme.media.tablet`
    justify-content: center;
    width: 100%;
  ` }
`

const VerticalRule = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${ props => props.theme.black };
`

export const InstaLink = props => (
  <InstaLinkStyle
    href={ INSTAGRAM_LINK }
    target="_blank"
    content="nofollow"
    rel="noopener noreferrer">
    { props.children }
  </InstaLinkStyle>
)

export const ComingSoon = props => (
  <Layout>
    <LayoutLeft>
      <InstaLink>
        <InstagramImage dim={150} src={ InstagraPic } />
      </InstaLink>
    </LayoutLeft>
    <LayoutRight>
      <InstaLink
        href={ INSTAGRAM_LINK }
        target="_blank"
        content="nofollow"
        rel="noopener noreferrer">
        <InstagramIcon />
        <VerticalRule />
        <Handle>{ INSTAGRAM_HANDLE }</Handle>
      </InstaLink>
    </LayoutRight>
  </Layout>
)
