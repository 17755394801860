import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const LogoLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    border: none;
    text-decoration: none;
  }
`

const LogoImageSmall = styled.div`
  max-height: ${ props => props.theme.navHeight };
  padding: 5px 0;
  display: flex;
  align-items: center;
`

const LogoSmall = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "logo-short.png" }) {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }`
    }
    render={ data => (
      <LogoLink to="/">
        <LogoImageSmall>
          <Img
            loading="eager"
            fadeIn={false}
            fixed={data.logo.childImageSharp.fixed} />
        </LogoImageSmall>
      </LogoLink>
    )} />
)

export {
  LogoSmall
}

